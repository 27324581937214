/* En tu archivo CSS */
.background-image {
    background-image: url('../../images/bg1.jpg'); /* Ruta de la imagen */
    background-size: cover; /* Hace que la imagen cubra todo el contenedor */
    background-position: center; /* Centra la imagen */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    height: 100%; /* Ajusta según el tamaño que necesites */
    width: 100%; /* Ajusta el ancho completo */
    position: relative;
    /* z-index:-1000; */
  }
  